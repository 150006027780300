.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.main-content {
    padding: 20px;
}

.sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #333;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1000;
    overflow-y: auto; /* Add this to make the sidebar scrollable */
}

.sidebar-button {
    display: block;
    color: #f2f2f2;
    padding: 10px 15px;
    text-decoration: none;
    font-size: 16px;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
}

.sidebar-button:hover {
    background-color: #ddd;
    color: black;
}

.sidebar-button.active {
    background-color: #ddd;
    color: black;
}

.submenu {
    margin-top: 20px;
}

.submenu p {
    color: #f2f2f2;
    margin: 10px 0;
    font-size: 18px;
}

.page-content {
    margin-top: 80px; /* Add some space for the fixed navbar */
    display: flex;
    flex-direction: column;
    align-items: center;
}
