.nav-bar {
    background-color: #333;
    overflow: hidden;
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.nav-button {
    margin: 0 10px; /* Add some spacing between buttons */
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    background: none; /* Ensure buttons don't have a background by default */
    border: none; /* Remove default button border */
    cursor: pointer; /* Ensure pointer cursor for buttons */
}

.nav-button:hover, .nav-button.active {
    background-color: #ddd;
    color: black;
}

.page-content {
    margin-top: 80px; /* Add some space for the fixed navbar */
}
