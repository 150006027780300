.page-content {
    margin-top: 80px; /* Add some space for the fixed navbar */
    padding: 20px;
}

.words-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.words-table th, .words-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
    vertical-align: middle;
}

.words-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-transform: uppercase;
}

.words-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.words-table tr:hover {
    background-color: #f1f1f1;
}

.input-cell {
    display: flex;
    align-items: center;
}

.input-cell input[type="text"], .input-cell span {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    padding: 8px;
}

.delete-button, .submit-button {
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.delete-button {
    background-color: #ff4d4d;
    color: white;
}

.delete-button:hover {
    background-color: #d93636;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
}

.submit-button:hover {
    background-color: #45a049;
}

input[type="text"], select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.language-selector {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.language-selector label {
    margin-right: 10px;
    font-weight: bold;
    text-transform: uppercase;
}
